import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser'
import axios from 'axios'

import { apiConfig, msalConfig } from '../authConfig'

const msalInstance = new PublicClientApplication(msalConfig)

const apiClient = axios.create({
  baseURL: apiConfig.webApiUri,
  headers: {
    'Content-Type': 'application/json',
  },
})

msalInstance.initialize().then(() => {
  apiClient.interceptors.request.use(async (config) => {
    const account = msalInstance.getActiveAccount()
    if (account) {
      const request = {
        scopes: ['Mail.Read'],
        loginHint: account.username,
      }

      const tokenResponse = await msalInstance
        .acquireTokenSilent({
          scopes: apiConfig.b2cScopes,
          account: account,
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return msalInstance.acquireTokenRedirect(request)
          }
        })
      if (tokenResponse) config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`
    }
    return config
  })
})

export default apiClient
